// 密码正则
const password = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/
// 手机号正则
const phone = /^1[3-9]\d{9}$/

// 邮箱正则
const email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
//验证码
const captcha = /^\d{6}$/
export const RegExpHelper = {
    password, phone, email, captcha
}